<template>
  <div id="chart" class="grow flex-wrap" >
    <apexchart
      type="bar"
      :height=height
      :width=width
      :options="bar.chartOptions"
      :series="bar.series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  
  name: "Bar",
  props: ["data", "height", "width"],
  data() {
    return {
      bar: {
        series: [
          {
            data: this.data.yAxis,
          },
        ],
        chartOptions: {
          annotations: {
            points: [
              {
                x: "Bananas",
                seriesIndex: 0,
                label: {
                  borderColor: "#775DD0",
                  offsetY: 0,
                  style: {
                    color: "#fff",
                    background: "#775DD0",
                  },
                  text: "Bananas are good",
                },
              },
            ],
          },
          chart: {
            height: 350,
            type: "bar",
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: "50%",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 2,
          },

          grid: {
            row: {
              colors: ["#Dfff", "#f2f2f2"],
            },
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
            categories: this.data.xAxis,
            tickPlacement: "on",
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.85,
              opacityTo: 0.85,
              stops: [50, 0, 100],
            },
          },
        },
      },
    };
  },
};
</script>

<style>
</style>