<template>
  <section class="container-fluid m-10">
    <v-row class="m-0">
      <div class="col col-lg-9 p-0"></div>
    </v-row>

    <v-row class="m-0 graphe-center">
      <div v-if="this.current.id == null && this.current.libelle == null">
        <p>Veuillez selectionner un dashboard</p>
      </div>

      <v-col
        v-for="chart in this.$store.state.ChartItems"
        :key="chart.id"
        :class="getClass(chart.id)"
      >
        <template>
          <div style="padding: 10px" class="v-sheet elevation-6 default">
            <div style="position: absolute; z-index: 99">
              <v-icon @click.prevent="SetChartSize(chart.id, '+')"
                >mdi-plus-circle-outline</v-icon
              >
              <v-icon @click.prevent="SetChartSize(chart.id, '-')"
                >mdi-minus-circle-outline</v-icon
              >
            </div>
            <component
              :is="chart.type"
              :color="chart.data.color"
              :data="chart.data"
              :height="400"
            ></component>
          </div>
        </template>
        <h4 class="card-title font-weight-light mt-2 ml-2">
          {{ chart.libelle }}
        </h4>
        <v-btn
          class="ma-1 white--text"
          @click="DeleteChartFromScreen(chart.id)"
        >
          <v-icon>mdi-eye-off-outline</v-icon>
        </v-btn>
        <v-btn @click.prevent="RefreshChartData(chart.id)">
          <v-icon>mdi-database-refresh-outline</v-icon>
        </v-btn>
        <v-btn id="loading" outlined disabled></v-btn>
        <span class="dropdown mt-1 white--text" style="float: right !important">
          <v-btn class="dropbtn">
            <v-icon>mdi-triangle-small-down</v-icon>
          </v-btn>
          <div class="dropdown-content">
            <div v-for="TypeChart in AllTypeChart" :key="TypeChart.id">
              <span
                v-if="TypeChart.groupe_id == chart.groupe_id"
                @click.prevent="ChangeChartType(chart, TypeChart)"
                >{{ TypeChart.libelle }}</span
              >
            </div>
          </div>
        </span>
        <h5 class="font-weight-light mt-2 ml-2">
          {{ chart.libelle }}
        </h5>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DistributedTreemap from "../../charts/Treemap/DistributedTreemap.vue";
import Bar from "../../charts/Bar/Bar.vue";
import Pie from "../../charts/Pie/Pie.vue";
import Radar from "../../charts/Radar/Radar.vue";
import RadialRadar from "../../charts/Radar/RadialRadar.vue";
import Scatter from "../../charts/Scatter/Scatter.vue";
import MultiseriesTimeline from "../../charts/Timeline/Multiseries.vue";
import BasicTimeline from "../../charts/Timeline/Basic.vue";
import DashedLine from "../../charts/Line/Dashed.vue";
import BasicLine from "../../charts/Line/Basic.vue";
import ZoomableTimeseries from "../../charts/Line/ZoomableTimeseries.vue";
import LineDatatable from "../../charts/Line/LineDatatable.vue";
import ColumnBasic from "../../charts/Bar/ColumnBasic.vue";
import ColumnDatalabel from "../../charts/Bar/ColumnDatalabel.vue";
import StackedColumn from "../../charts/Bar/StackedColumn.vue";
import ColumnWithNegativeValue from "../../charts/Bar/ColumnWithNegativeValue.vue";
import ColumnWithMarkers from "../../charts/Bar/ColumnWithMarkers.vue";
import BasicPolarArea from "../../charts/Polar/BasicPolarArea.vue";
import BasicArea from "../../charts/Area/Basic.vue";

import API from "../api-config.js";
export default {
  components: {
    Pie,
    BasicLine,
    ZoomableTimeseries,
    DashedLine,
    LineDatatable,
    ColumnBasic,
    ColumnDatalabel,
    StackedColumn,
    ColumnWithNegativeValue,
    ColumnWithMarkers,
    BasicTimeline,
    MultiseriesTimeline,
    BasicPolarArea,
    Bar,
    Scatter,
    Radar,
    RadialRadar,
    BasicArea,
    DistributedTreemap,
  },

  data() {
    return {
      charts: [],
      selectedValue: null,
      items: [],
      SIZE_MIN: 4,
      SIZE_MAX: 12,
      SIZE_GO: 1,
      label: "Selectionner un Graphe",
      endpoint: API.APIendpoint,
      DashboardAPI: "graphe/dashboard",
      token: localStorage.getItem("token"),
      componentKey: 0,
      AllTypeChart: [],
    };
  },
  computed: {
    dataItem() {
      return this.$store.state.DataItem;
    },
    current() {
      return this.$store.state.CurrentActiveDashboard;
    },
    ChartItems() {
      return this.$store.state.ChartItems;
    },
    SizeItems() {
      return this.$store.state.SizeItems;
    },
  },

  methods: {
    SetChartSize(id, SizeOption) {
      for (let i = 0; i < this.SizeItems.length; i++) {
        if (id == this.SizeItems[i].id) {
          if (SizeOption == "+") {
            if (this.SizeItems[i].size <= this.SIZE_MAX - this.SIZE_GO) {
              this.SizeItems[i].size =
                parseInt(this.SizeItems[i].size) + this.SIZE_GO;
            }
          }
          if (SizeOption == "-") {
            if (this.SizeItems[i].size >= this.SIZE_MIN + this.SIZE_GO) {
              this.SizeItems[i].size =
                parseInt(this.SizeItems[i].size) - this.SIZE_GO;
            }
          }

          this.$http
            .put(
              this.endpoint + this.DashboardAPI,
              {
                graphe_id: id,
                du_id: this.current.id,
                size: this.SizeItems[i].size,
              },
              { headers: { token: this.token } }
            )
            .then(
              (response) => {
                //callback response
              },
              (response) => {
                //callback error
              }
            );
          break;
        }
      }
    },
    getClass(id) {
      let temp = null;
      for (let i = 0; i < this.SizeItems.length; i++) {
        if (id == this.SizeItems[i].id) {
          temp = this.SizeItems[i].size;
          break;
        }
      }
      return "col-lg-" + temp + " v-card v-sheet";
    },
    DeleteChartFromScreen(id) {
      this.$http
        .delete(this.endpoint + this.DashboardAPI, {
          headers: { token: this.token },
          body: { graphe_id: id, du_id: this.current.id },
        })
        .then(
          (response) => {},
          (response) => {
            //callback error
          }
        );

      this.$store.commit("FilterChartItems", id);
      //ici desactiver celui de lidddd et non du tableau

      /* console.log("avant.....")
     console.log(this.dataItem);  */

      this.dataItem[id - 1].disabled = !this.dataItem[id - 1].disabled;

      /*     console.log("apres.....")
     console.log(this.dataItem);  */
      this.label = "Selectionner un Graphe";
      this.selectedValue = null;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    ChangeChartType(chart, newType) {
      console.log("graphe id : " + chart.id);
      console.log("id du nouveau type : " + newType.id);
      let API = "Graphe/changeType";
      this.$http
        .put(
          this.endpoint + API,
          { graphe_id: chart.id, graphe_type_id: newType.id },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then(
          (response) => {
            console.log(response.body);
          },
          (response) => {}
        );

      chart.type = newType.code;
    },
    getAllTypeChart() {
      let du_id = null;
      let API = "graphe/getAllTypeChart";
      this.$http
        .get(this.endpoint + API, { headers: { token: this.token } })
        .then(
          (response) => {
            //console.log(response.body.data);
            this.AllTypeChart = response.body.data;
            //console.log(this.AllTypeChart);
          },
          (response) => {
            //callback error
          }
        );
    },
    RefreshChartData(id) {
      //console.log(id);
      //document.getElementById("loading").classList.add("loading");
      let API = "synchronisation/sync";
      this.$http
        .post(
          this.endpoint + API,
          { graphe_id: id },
          { headers: { token: localStorage.getItem("token") } }
        )
        .then(
          (response) => {
            //console.log(response.body);
            // document.getElementById("loading").classList.remove("loading");
          },
          (response) => {
            //callback error
            // document.getElementById("loading").classList.remove("loading");
          }
        );
    },
  },
  mounted() {
    this.getAllTypeChart();
    let du_id = null;
    let API = "Dashboard/user";
    this.$http
      .get(this.endpoint + API, { headers: { token: this.token } })
      .then(
        (response) => {
          let data = response.body.data;
          //console.log(data);
          let DashboardItems = [];
          for (let i = 0; i < data.length; i++) {
            let temp = {
              id: parseInt(`${data[i].id}`),
              libelle: `${data[i].libelle}`,
              editable: false,
              active: false,
            };
            if (data[i].actif == 1) {
              temp.active = true;
              this.$store.commit("SwitchDashboard", temp);
              this.$store.commit("LoadCharts", data[i].id);
              this.$store.commit("LoadSelectItems", data[i].id);
            }
            DashboardItems.push(temp);
          }
          this.$store.commit("LoadDashboardItems", DashboardItems);
        },
        (response) => {
          //callback error
        }
      );
  },
};
</script>

<style scoped>
.TypeChart {
  display: flex !important;
  flex-direction: column !important;
}
#loading {
  display: inline-block !important;
  /* position: relative !important; */
  /*  width:200px !important; */
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  background-size: cover;
  background-color: inherit !important;
}
.loading {
  background-image: url("../assets/images/loading.gif");
  height: 50px; /* You must set a specified height */
  background-position: center; /* Center the image */
  font-size: 0 !important;
  background-color: #f2f2f2 !important;
  border: 1px dotted !important;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 5px;
}
.v-sheet {
  margin: 10px 0;
}
.graphe-center {
  display: flex;
  justify-content: space-around;
}

.m-10 {
  margin: 10px;
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}
h4 {
  margin-bottom: 0 !important;
}
.v-dropdown-item.disabled[data-v-138dff1d]:hover {
  background-color: grey;
}
/* Style The Dropdown Button */
.dropbtn {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: gray;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content span {
  color: black;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  width: 300px;
  z-index: 100;
}
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
</style>
